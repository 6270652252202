import { PromoteUser, DemoteUser, AssignChapterMember, UnassignChapterMember } from "./operations.gql";
import * as BE from "@/utils/api/backendException.js";
import { hasuraClient } from "@/utils/graphql/apollo.js";
import { call } from "@/utils/api/base.js";

export async function promoteUser(userId, chapterId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "User or Chapter does not exist."],
    [BE.NOT_BELONG, "Can not promote someone who is not a member of the Chapter."],
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error promoting user."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: PromoteUser,
        variables: {
          userId,
          chapterId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function demoteUser(userId, chapterId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "User or Chapter does not exist."],
    [BE.NOT_BELONG, "Can not demote someone who is not a member of the Chapter."],
    [BE.PERMISSION, "Must first remove this user as an organizer for all Series within the Chapter."]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error demoting user."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: DemoteUser,
        variables: {
          userId,
          chapterId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function assignChapterMember(userId, chapterId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.PERMISSION, "You do not have permission to assign chapter member on behalf of User."],
    [BE.NOT_EXIST, "Chapter or user not found."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Error assigning chapter member."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: AssignChapterMember,
        variables: {
          userId,
          chapterId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function unassignChapterMember(userId, chapterId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.PERMISSION, "You don't have permission to unassign this chapter member. You can only remove yourself if you are not an admin."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.DELETE_FAILED, "Error unassigning chapter member."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UnassignChapterMember,
        variables: {
          userId,
          chapterId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}
